import React, { useEffect, useState } from "react";
import pdfPort from "../../../public/port.pdf";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useBlog } from "../../Context/BlogContext";
import { useNavigate } from "react-router-dom";
import { useProducts } from "../../Context/ProductsContext";

const PersonProfile = () => {
  const { articles, getArticles } = useBlog();
  const { products, getProducts } = useProducts();
  const navigate = useNavigate(); // Para la navegación programática

  useEffect(() => {
    getArticles();
    getProducts();
  }, []);

  const handleReadMore = (articleId) => {
    navigate(`/blog/${articleId}`); // Navega a la página del blog con el id del artículo
  };
  const handleProductMore = (productId) => {
    navigate(`/stock-devices/${productId}`); // Navega a la página del blog con el id del artículo
  };

  // Datos simulados para artículos del blog
  const blogArticles = [
    {
      title: "La tecnología en 2024",
      description: "Descubre las nuevas tendencias tecnológicas de este año.",
      image: "https://via.placeholder.com/650",
      link: "#",
    },
    {
      title: "La IA en la vida diaria",
      description:
        "Cómo la inteligencia artificial está cambiando nuestras vidas.",
      image: "https://via.placeholder.com/650",
      link: "#",
    },
  ];

  // Datos simulados para productos tecnológicos
  const techProducts = [
    {
      name: "Smartphone XZ Pro",
      description: "El último smartphone con cámara de 108MP y pantalla 4K.",
      image: "https://via.placeholder.com/650",
      price: "$999",
      link: "#",
    },
    {
      name: "Laptop Gamer GX15",
      description: "La laptop ideal para gaming con tarjeta gráfica RTX 3080.",
      image: "https://via.placeholder.com/650",
      price: "$1999",
      link: "#",
    },
  ];

  return (
    <>
      <Navbar />
      <div className="min-h-screen p-10 mt-16">
        <div className="w-[80%] mx-auto bg-[#DFE1E3] shadow-lg rounded-lg overflow-hidden">
          <div className="w-full flex">
            {/* Columna izquierda - Información personal */}
            <div className="bg-[#0E4980] p-6 flex flex-col w-[45%]">
              <div className="flex items-center justify-start gap-4 pb-8">
                <h1 className="text-4xl font-bold text-blue-gray-100 mb-2">
                  Pepito Peréz Peréz
                </h1>
                <img
                  src="https://www.pngall.com/wp-content/uploads/5/Profile-Male-PNG.png"
                  alt="Infografía 2"
                  className="w-40 rounded-full shadow-md"
                />
              </div>
              <p className="text-gray-100 mb-6">
                Soy ... Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Iusto quo doloremque rem deleniti eveniet praesentium neque
                aliquam cum iste debitis.
              </p>
              <p className="text-gray-100 mb-6">
                Actualmente, me desempeño como Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Vero, impedit!.
              </p>

              {/* Contacto */}
              <div className="bg-[#BEDDF9] p-4 rounded-lg">
                <h3 className="text-3xl font-bold mb-2">Contáctame</h3>
                <p className="mb-2">
                  <a
                    href="mailto:Santiago.diseñador@correo.com"
                    className="text-blue-900"
                  >
                    pepito.perez@correo.com
                  </a>
                </p>
                <p className="mb-4">+57 3123456789</p>
                {/* Redes Sociales */}
                <div className="flex space-x-4">
                  <a href="#" className="text-2xl text-orange-500">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#" className="text-2xl text-blue-600">
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href="#" className="text-2xl text-yellow-600">
                    <i className="fab fa-behance"></i>
                  </a>
                  <a href="#" className="text-2xl text-gray-600">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </div>
                <div className="grid grid-cols-4 items-center">
                  <img
                    src="https://res.cloudinary.com/dtpshcfjx/image/upload/v1724707138/memberships/byqankpv47bmf0mymydb.png"
                    alt=""
                  />
                  <img
                    src="https://res.cloudinary.com/dtpshcfjx/image/upload/v1724707151/memberships/pyn30a26jkodphzo5zwq.png"
                    alt=""
                  />
                  <img
                    src="https://res.cloudinary.com/dtpshcfjx/image/upload/v1724707188/memberships/zt0dzwe2kbqipkl0loga.png"
                    alt=""
                  />
                  <img
                    src="https://res.cloudinary.com/dtpshcfjx/image/upload/v1724707342/memberships/oh4y7ejcdtgehcrgkjng.png"
                    alt=""
                  />
                </div>
              </div>
            </div>

            {/* Columna derecha - Proyectos */}
            <div className="w-[55%] p-6">
              <h2 className="text-3xl font-bold text-light-blue-900 mb-4">
                Portafolio
              </h2>

              {/* Mostrar el PDF usando un iframe */}
              <div className="w-full h-[600px]">
                <iframe
                  src={pdfPort}
                  title="Portafolio PDF"
                  className="w-full h-full border border-gray-300 rounded-lg shadow-md"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Sección de Productos Tecnológicos */}
        <div className="w-[80%] mx-auto mt-10">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            Productos que te pueden interesar
          </h2>
          <div className="grid grid-cols-2 gap-6">
            {products
              .filter((product) => product.category === "DaaS") // Filtra los productos por categoría "DaaS"
              .slice(0, 4) // Limita a los primeros 4 productos
              .map((product, index) => (
                <div key={index} className="bg-white shadow-lg rounded-lg p-4">
                  <div className="flex justify-center">
                    <img
                      src={product.images}
                      alt={product.name}
                      className="h-40 object-cover rounded-lg mb-4"
                    />
                  </div>
                  <h3 className="text-2xl font-bold mb-2">{product.name}</h3>
                  <p className="text-gray-700 mb-4">{product.description}</p>
                  <p className="text-green-600 font-bold mb-4">
                    {product.price}
                  </p>
                  <button
                    onClick={() => handleProductMore(product.id)} // Al hacer clic, navega al producto específico
                    className="text-blue-500 hover:underline"
                  >
                    Ver producto
                  </button>
                </div>
              ))}
          </div>
        </div>

        {/* Sección de Artículos del Blog */}
        <div className="w-[80%] mx-auto mt-10">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            No olvides estar actualizado con las ultimas noticias
          </h2>
          <div className="grid grid-cols-2 gap-6">
            {articles.slice(0, 4).map((article, index) => (
              <div key={index} className="bg-white shadow-lg rounded-lg p-4">
                <img
                  src={article.image}
                  alt={article.title}
                  className="w-full h-40 object-cover rounded-lg mb-4"
                />
                <h3 className="text-2xl font-bold mb-2">{article.title}</h3>
                <p className="text-gray-700 mb-4">{article.description}</p>
                <button
                  onClick={() => handleReadMore(article.id)} // Al hacer clic, navega al artículo específico
                  className="text-blue-500 hover:underline"
                >
                  Leer más
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PersonProfile;
